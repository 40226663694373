<a href="/posts.rss" title="RSS Feed">
	<svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 256 256">
		<title>RSS Feed</title>
		<defs>
			<linearGradient x1="0.085" y1="0.085" x2="0.915" y2="0.915" id="RSSg">
				<stop offset="0.0" stop-color="#e3702d" /><stop offset="0.1071" stop-color="#ea7d31" />
				<stop offset="0.3503" stop-color="#f69537" /><stop offset="0.5" stop-color="#fb9e3a" />
				<stop offset="0.7016" stop-color="#ea7c31" /><stop offset="0.8866" stop-color="#de642b" />
				<stop offset="1.0" stop-color="#d95b29" />
			</linearGradient>
		</defs>
		<rect width="256" height="256" rx="55" ry="55" x="0" y="0" fill="#cc5d15" />
		<rect width="246" height="246" rx="50" ry="50" x="5" y="5" fill="#f49c52" />
		<rect width="236" height="236" rx="47" ry="47" x="10" y="10" fill="url(#RSSg)" />
		<circle cx="68" cy="189" r="24" fill="#fff" />
		<path d="M160 213h-34a82 82 0 0 0 -82 -82v-34a116 116 0 0 1 116 116z" fill="#fff" />
		<path d="M184 213A140 140 0 0 0 44 73 V 38a175 175 0 0 1 175 175z" fill="#fff" />
	</svg>
</a>

<style>
	svg {
		width: 1em;
		height: 1em;
	}
</style>
