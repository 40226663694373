<a target="_blank" rel="noreferrer noopener" title="Twitch" href="https://twitch.tv/esthedebeste">
	<svg xmlns="http://www.w3.org/2000/svg" fill="#6441a5" viewBox="0 0 2400 2800">
		<title>Twitch</title>
		<polygon
			fill="#fff"
			points="2200,1300 1800,1700 1400,1700 1050,2050 1050,1700 600,1700 600,200 2200,200 	"
		/>
		<path
			d="M500,0L0,500v1800h600v500l500-500h400l900-900V0H500z M2200,1300l-400,400h-400l-350,350v-350H600V200h1600V1300z"
		/>
		<rect x="1700" y="550" width="200" height="600" />
		<rect x="1150" y="550" width="200" height="600" />
	</svg>
</a>

<style>
	svg {
		width: 1em;
		height: 1em;
	}
</style>
